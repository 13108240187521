<template lang="pug">
  .home
    .header
      div.primaryDark
        .navbar.pa-4.mx-auto(style="max-width:600px")
          v-row(align="center")
            v-col
              .subtitle-1.textYellow--text.font-weight-bold Jumaat, 18 Jun 2021
              .body-2.textYellow--text.mt-n1 7 Zul Kaedah 1442H
            v-col.shrink
              v-btn.my-auto(text depressed rounded)
                v-icon(color="white") mdi-dots-vertical
        
        .content.text-center.pb-16
          div.d-flex
            v-spacer
            .subtitle-1.white--text.mb-2.mr-2 Kuala Lumpur
            v-icon.mt-1(color="white" size="20") $icon-location
            v-spacer
          h1.white--text.mt-n2 Dhuhr
          .subtitle-1.grey--text 1jam 45 minit lagi

      div.mx-auto(style="max-width:600px;")
        .prayer.mt-n10.mx-2(style="background-image: linear-gradient(to bottom right, #DA94FD, #DA94FD); border-radius: 25px; height:76px;")
          v-row.px-4(no-gutter align="center")
            v-col.white--text.px-0(align="center")
              .title.caption Fajr
              .time.body-2.mt-n1 6:15 AM
            v-col.textBlack--text.px-0.my-2(align="center" style="background-color: white; border-radius: 18px;")
              .title.caption Dhuhr
              .time.body-2.mt-n1 1:29 PM
            v-col.white--text.px-0(align="center")
              .title.caption Fajr
              .time.body-2.mt-n1 6:15 AM
            v-col.white--text.px-0(align="center")
              .title.caption Fajr
              .time.body-2.mt-n1 6:15 AM
            v-col.white--text.px-0(align="center")
              .title.caption Fajr
              .time.body-2.mt-n1 6:15 AM
      
      .extra.pa-4.mx-auto(style="max-width:600px;")
        .functions
          .title.font-weight-bold Fungsi
          v-row.px-1.mt-1(no-gutter align="center")
            v-col.px-0.my-2.elevation-1.mr-2(align="center" style="background-color: #F8F5FC; border-radius: 25px;")
              v-icon.mt-1(color="primary" size="28") $icon-halal-food
              .title.caption.mt-2 Makanan
              .time.caption.mt-n1 Halal
            v-col.px-0.my-2.elevation-1.mr-2(align="center" style="background-color: #F8F5FC; border-radius: 25px;")
              v-icon.mt-1(color="primary" size="28") $icon-calendar
              .title.caption.mt-2 Kalendar
              .time.caption.mt-n1 Hijri
            v-col.px-0.my-2.elevation-1.mr-2(align="center" style="background-color: #F8F5FC; border-radius: 25px;")
              v-icon.mt-1(color="primary" size="28") $icon-qiblat
              .title.caption.mt-2 Qiblat
              .time.caption.mt-n1 &nbsp;
            v-col.px-0.my-2.elevation-1(align="center" style="background-color: #F8F5FC; border-radius: 25px;")
              v-icon.mt-1(color="primary" size="28") $icon-hadis
              .title.caption.mt-2 Hadis
              .time.caption.mt-n1 &nbsp;
        
        .search.mt-6
          .title.font-weight-bold Carian
          v-text-field.mt-2(rounded outlined clearable append-icon="mdi-magnify" placeholder="Carian" hide-details="auto")
          .suggestion.ma-2
            div.d-flex
              div.mr-3.my-auto cadangan:
              v-chip.mr-2(rounded outlined) Solat
              v-chip.mr-2(rounded outlined) Hadis
              v-chip.mr-2(rounded outlined) Puasa

</template>

<script>
export default {
  name: 'Home',
  data: () => ({ 
    url: 'https://assets.tilawah.my/8-Ry.mp3'
  }),
  activated () {
    console.log('activated!');
    // this.playAudio()
  },
  methods: {
    async playAudio() {
      const data = await this.$plugins.playAudio({ value: this.url})
      console.log(data)
    }
  }
}
</script>
